import React, { lazy, Suspense } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import { addComponent } from '@grafana-cloud/ui';
import { CollectorInstallationInstructionsExtensionProps } from 'extensions/CollectorInstallationInstructionsExtension';
import { AppJSONData } from 'feature/common/types/AppJSONData';

import { ComingSoon } from './feature/app/components/ComingSoon';
import { isCollectorAppEnabled } from './feature/common/utils/featureToggles';

/** Faro */
let initialized = false;
async function init() {
  if (initialized) {
    return;
  }
  initialized = true;
  const { initFaro } = await import('faro/initialize');

  initFaro();
}

/** App */
const LazyApp = lazy(async () => {
  await init();
  return await import('./feature/app/components/App');
});

const App = (props: AppRootProps<AppJSONData>) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

/** Extensions */
const LazyCollectorInstallationInstructionsExtension = lazy(
  () => import('extensions/CollectorInstallationInstructionsExtension')
);

const CollectorInstallationInstructionsExtension = (props: CollectorInstallationInstructionsExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyCollectorInstallationInstructionsExtension {...props} />
  </Suspense>
);

/** Plugin */
// eslint-disable-next-line import/no-unused-modules
export const plugin = new AppPlugin<AppJSONData>().setRootPage(isCollectorAppEnabled() ? App : ComingSoon);

addComponent<CollectorInstallationInstructionsExtensionProps>(plugin, {
  title: 'Collector Installation Instructions',
  description: 'UI for displaying collector installation instructions.',
  targets: 'grafana/grafana-collector-app/collector-installation-instructions/v1',
  component: CollectorInstallationInstructionsExtension,
});
