import { FeatureToggles } from '@grafana/data';
import { config } from '@grafana/runtime';

enum CollectorFeatureToggle {
  collectorApp = 'collectorApp',
  fleetManagement = 'fleetManagement',
}

type CollectorFeatureToggles = FeatureToggles & {
  [key in CollectorFeatureToggle]: boolean;
};

function isFeatureToggleEnabled(featureName: CollectorFeatureToggle): boolean {
  return (config.featureToggles as CollectorFeatureToggles)[featureName];
}

export function isCollectorAppEnabled(): boolean {
  return isFeatureToggleEnabled(CollectorFeatureToggle.collectorApp);
}

export function isFleetManagementEnabled(): boolean {
  return isFeatureToggleEnabled(CollectorFeatureToggle.fleetManagement);
}
